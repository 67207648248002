define("@embroider/util/services/ensure-registered", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EnsureRegisteredService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "classNonces", new WeakMap());
      (0, _defineProperty2.default)(this, "nonceCounter", 0);
    }
    register(klass) {
      let owner = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.getOwner(this);
      let nonce = this.classNonces.get(klass);
      if (nonce == null) {
        nonce = `-ensure${this.nonceCounter++}`;
        this.classNonces.set(klass, nonce);
        owner.register(`component:${nonce}`, klass);
      }
      return nonce;
    }
  }
  _exports.default = EnsureRegisteredService;
});